import React, {useState, useEffect} from "react";
import {
    web3,
    web3getAccount,
    CheckAllowance2,
    addAllowance2,
    getMyInfo2,
    getMyInfoToClaim,
    preSaleTimes2,
    totalSolds,
    totalSolds2,
    buyPBX2,
    claimPBX2,
    CheckBUSDBalance
  } from "../web3/web3Provider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Button, Form, ListGroup, Spinner, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

import Header from '../Components/Header2';
import Swal from 'sweetalert2'


function PresaleV2() {

    // Check Metamask installed.
    if(typeof window.ethereum === 'undefined') {
      window.location.href = "./";
      throw new Error();
    }

    const [tradeEnable, setTradeEnable] = useState({})
    const [userInfo, setUserInfo] = useState({})
    const [totalSold1, setTotalSold1] = useState([])
    const [totalSold2, setTotalSold2] = useState([])
    const [totalSoldSum, setTotalSoldSum] = useState([])
    const [dataPresaleTime, setDataPresaleTime] = useState({})
    const [currentTimes, setCurrentTimes] = useState([])
    const [country, setcCountry] = useState([]);

    let userFullname = [{"fname": "null", "lname": "null"}];

    const fetchURL = async (url) => {
        try{
            const fetchs = await fetch(url);
            const datas = await fetchs.json();
            return datas;
        } catch(err){
            return err;
        }
    }

    
    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }
    
    
    const chkAllowance = () => {
        web3getAccount().then((res) => {
          CheckAllowance2(res).then((result) => {
            setTradeEnable({"status":result})
          })
        })
    }

    const chkUserInfo = async () => {
        await web3getAccount().then((res) => {

                getMyInfo2(res).then( async (result) => {
                    await setUserInfo({
                        "email": result._email,
                        "fname": result._fname,
                        "lname": result._lname,
                        "country": result._country,
                        "totalBUSD": web3.utils.fromWei(`${result._amountBUSD}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        "totalPBX": web3.utils.fromWei(`${result._PBXclaim}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        "_totalPBX": parseInt(result._PBXclaim)
                    })
                })
        })
    }

    const chkPreSaleTimes = async () => {
            await preSaleTimes2().then( async (result) => {
                // console.log("Presale End : %s", Number(result[3]))
                setDataPresaleTime({
                    "end": Number(result[3])
                })
            })
    }

    const chkTotalSolds = async () => {
        await totalSolds().then( async (result1) => {
            await setTotalSold1([web3.utils.fromWei(`${result1}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")])

            await totalSolds2().then( async (result2) => {
                await setTotalSold2([web3.utils.fromWei(`${result2}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")])
                await setTotalSoldSum([( parseFloat(web3.utils.fromWei(`${result1}`))+parseFloat(web3.utils.fromWei(`${result2}`)) ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")])
            })
        })
    }

    
    const checkIsApprove = () => {
        let isApprove = tradeEnable.status;
        let btnReload = document.querySelector("#btnReload");
        if(isApprove === true){
            btnReload.style.display = "none";
            return(
            <>
                {
                    currentTimes >= dataPresaleTime.end
                    ? (
                        <Button variant="warning" id="btnBuyPBX" className="goldenBack w-100" style={{ height: "50px"}} onClick={ () => window.location.href="https://p2pb2b.io/trade/PBX_BUSD/" }>
                            <b>Presale End, Please go to buy PBX on P2PB2B.IO</b>
                        </Button>
                    )
                    : (
                        <>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="text" className="text-center" id="txtInputFname" placeholder="Enter first name" onInput={ (e) =>  { userFullname.fname = e.target.value } } />
                                <Form.Control type="text" className="text-center" id="txtInputLname" placeholder="Enter last name" onInput={ (e) => userFullname.lname = e.target.value } />
                                <Form.Control type="email" className="text-center" id="txtInputEmail" placeholder="Enter email" onInput={ (e) => setEmail(e.target.value) } />
                                <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Select className="text-center" onChange={(e) => setTotalBuy(e.target.value) }>
                                <option value="30">1,000 PBX - 30 BUSD</option>
                                <option value="90">3,000 PBX - 90 BUSD</option>
                                <option value="150">5,000 PBX - 150 BUSD</option>
                                <option value="300">10,000 PBX - 300 BUSD</option>
                                <option value="900">30,000 PBX - 900 BUSD</option>
                                <option value="1500">50,000 PBX - 1,500 BUSD</option>
                                <option value="3000">100,000 PBX - 3,000 BUSD</option>
                                <option value="9000">300,000 PBX - 9,000 BUSD</option>
                                <option value="15000">500,000 PBX - 15,000 BUSD</option>
                                <option value="30000">1,000,000 PBX - 30,000 BUSD</option>
                                <option value="90000">3,000,000 PBX - 90,000 BUSD</option>
                                <option value="150000">5,000,000 PBX - 150,000 BUSD</option>
                            </Form.Select>
                            <br/>
                            <Button variant="warning" id="btnBuyPBX" className="goldenBack w-100" style={{ height: "50px"}} onClick={() => btnBuy() }>
                                <b>BUY</b>
                            </Button>
                            <p><small>Minimum buy is 1,000 PBX</small></p>
                        </>
                    )
                }
            </>
            )
        }
        
        if(isApprove === false){
            btnReload.style.display = "none";
            return(<Button variant="secondary" className="w-100" style={{ height: "50px"  }} onClick={() => enableTrade2()}>Connect wallet {String('&')} Approve</Button>)
        }

    }
    

    const enableTrade2 = () => {
        web3getAccount().then((res) => {
            addAllowance2(res)
        })
    }

    const btnBuy = () => {
        
        if(userFullname.fname === undefined)
        {
            Swal.fire({
                icon: 'warning',
                title: 'It\'s wrong!',
                text: 'First name is empty. Please check again.',
            }).then(() => {
                document.querySelector("#txtInputFname").focus();
            })
            return;
        }
        if(userFullname.lname === undefined)
        {
            Swal.fire({
                icon: 'warning',
                title: 'It\'s wrong!',
                text: 'Last name is empty. Please check again.',
            }).then(() => {
                document.querySelector("#txtInputLname").focus();
            })
            return;
        }
        if(isEmailOK === false )
        {
            Swal.fire({
                icon: 'warning',
                title: 'It\'s wrong!',
                text: 'Email invalid. Please check again.',
            }).then(() => {
                document.querySelector("#txtInputEmail").focus();
            })
            return;
        }

        

        web3getAccount().then(async (res) => {
            await CheckBUSDBalance(res).then( async(busdBalance) =>{
                if(busdBalance < parseFloat(totalBuyAmount)){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error!',
                        html: '<h1>Your BUSD is less '+totalBuyAmount+'</h1>',
                        footer: '<b>Have <span class="text-danger">'+busdBalance.toFixed(2)+'</span> BUSD</b>'
                    })
                    return;
                }
                await fetch("https://showcase.api.linx.twenty57.net/UnixTime/tounix?date=now")
                .then((response) => response.json())
                .then( async (data) => {
                    await buyPBX2(data, totalBuyAmount, userEmail, userFullname.fname, userFullname.lname, country[0], res)
                })
            })
        })
        // console.log("Fname %s | Lname %s", userFullname.fname, userFullname.lname)
        // console.log("Buy %s BUSD | Email %s | isEmailOK = %s | Country = %s", totalBuyAmount, userEmail, isEmailOK, country[0])
    }

    const btnClaimPBX = () => {
        web3getAccount().then((res) => {
            claimPBX2(res)
        })
    }


    // Set Variable.
    let sellerWithdraw = 0;


    let totalBuyAmount = 30;
    let userEmail = "";
    let isEmailOK = false;

    // Presale Function
    const setTotalBuy = (total) => {
        totalBuyAmount = Number(total);
    }

    const setEmail = (email) => {
        userEmail = email;
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(email) === true) isEmailOK = true;
        if(re.test(email) === false) isEmailOK = false;
    }

    useEffect(() => {
        chkAllowance()
        fetchURL("https://ip.seeip.org/geoip").then( async (res) => setcCountry([res.country]))
        chkUserInfo()
        chkTotalSolds()
        chkPreSaleTimes()
        
        web3.eth.getBlockNumber().then((res) => web3.eth.getBlock(res).then((res2) => { setCurrentTimes([res2.timestamp]); console.log(`Current block Timestamps : ${res2.timestamp} ( ${timeConverter(res2.timestamp)} )`) }));
    }, []);
    


    return (
      
      <>
        <Header />

        <div className="text-center">
            <br/>
            <p>
              <img src="pbx.gif" alt="logo" />
            </p>
            <br />
            <br />
            <br/>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "15px",
                color: "rgb(90, 96, 108)",
              }}
            >
              <Container style={{ fontSize: "18px", maxWidth: "838px" }}>
              <Row>
                  <Col lg="12">
                      <Card style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <Col lg="12" className="d-flex justify-content-between" style={{ padding: ".85rem" }}>
                                <Button variant="warning" className="goldenBack" onClick={ () => window.location.href="/presale" } style={{ width: "350px", height: "70px", margin: "0px 10px 0px 10px" }}>Presale V1<br/>( 2021 )</Button>
                                <Button variant="warning" className="goldenBack" style={{ width: "350px", height: "70px", margin: "0px 10px 0px 10px", border: "3px solid #333", boxShadow: "#8f6B29 0px 0px 20px 0px" }}>Presale V2<br/>( 2022 )</Button>
                            </Col>
                          </Card.Body>
                      </Card>
                      <br/>
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <Col lg="12" className="d-flex justify-content-between" style={{ padding: ".85rem" }}>
                                <span>My Detail</span>
                                <span>{ userInfo.email === "" ? "You haven't bought." : userInfo.email }</span>
                            </Col>
                            <Col lg="12" className="text-end" style={{ padding: ".85rem" }}>
                                { 
                                userInfo.email === "" 
                                    ? "Data not found." 
                                    : (
                                        <>
                                        <span>{ userInfo.email === "" ? "Not found." : userInfo.fname }</span><br/>
                                        <span>{ userInfo.email === "" ? "Not found." : userInfo.lname }</span><br/>
                                        <span>{ userInfo.email === "" ? "Not found." : userInfo.country }</span>
                                        </>
                                    )
                                }
                            </Col>
                            <hr/>
                            <Col className="d-flex justify-content-between" style={{ padding: ".85rem" }}>
                                <span>Total BUSD</span>
                                <span><span>{ userInfo.totalBUSD }</span> BUSD</span>
                            </Col>
                            <hr/>
                            <Col className="d-flex justify-content-between" style={{ padding: ".85rem" }}>
                                <span>Total PBX</span>
                                <span><span>{ userInfo.totalPBX }</span> PBX</span>
                            </Col>
                          </Card.Body>
                      </Card>
                      <br/>
                  </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center" style={{ padding: ".85rem" }}>
                        <br/><br/>
                        <p><Link to={{ pathname: "https://bscscan.com/address/0x5c19C499a08E6bdc148D80b71686F2f2f33E7B63"}} target="_blank" style={{ fontSize: "15px", textDecoration: 'none' }}>View Transaction History on BSCSCAN</Link></p>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Card style={{ backgroundColor: "#eff6ff" }}>
                            <Card.Body>
                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>Buy PBX Token</p>

                                { checkIsApprove() }
                                <Button variant="danger" className="w-100" id="btnReload" style={{ height: "50px"  }} onClick={() => window.location.reload()}>Please unlock wallet and reload this page.</Button>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Card style={{ backgroundColor: "#eff6ff" }}>
                            <Card.Body>
                                <p style={{ fontSize: "12px!important", letterSpacing: ".3em" }}>Claim PBX</p>
                                  <span>{ userInfo.totalPBX }</span>
                                  <br/><br/>
                                {
                                    (userInfo._totalPBX > 0)
                                    ? <Button variant="warning" id="btnClaimPBX" className="goldenBack w-100" style={{ height: "50px"  }} onClick={ () => btnClaimPBX() }>Claim</Button>
                                    : <Button variant="warning" className="goldenBack w-100" style={{ height: "50px"  }} disabled>Claim</Button>
                                }
                                <br/>
                                <ListGroup variant="flush" className="text-start" style={{ fontSize: "14px", marginTop: "5px", border: "1px solid #ccc", borderRadius: "10px" }}>
                                    <ListGroup.Item>
                                        <p>Claim fee 0.1%.</p>
                                    </ListGroup.Item>
                                </ListGroup>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <br /><hr />
                <Row>
                  <Col lg="12">
                    <br />
                    <span style={{ letterSpacing: ".5em" }}>Total Value Lock</span>
                    <br />
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col lg="12">
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px" }}> TVL Presale 1 :</span>
                              <span>{totalSold1} PBX</span>
                            </div>
                          </Card.Body>
                      </Card>
                      <br/>
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px" }}> TVL Presale 2 :</span>
                              <span>{totalSold2} PBX</span>
                            </div>
                          </Card.Body>
                      </Card>
                      <br/>
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px" }}> TVL Total :</span>
                              <span>{totalSoldSum} PBX</span>
                            </div>
                          </Card.Body>
                      </Card>
                      <br/>
                  </Col>
                </Row>

                {/* { 
                    web3getAccount().then((res) => {
                        if(res.toLocaleLowerCase() === ("0x1588584bEAFb94a5Db3293727fe31cAEa955419F").toLocaleLowerCase()){
                            return (
                                <> 
                                        <Row>
                                        <Col lg="12">
                                            <br />
                                            <span style={{ letterSpacing: ".5em" }}>Seller Withdraw</span>
                                            <br />
                                            <br />
                                        </Col>
                                        </Row>
                                        <Row>
                                        <Col lg="12">
                                            <div style={{ padding: "25px 25px 25px 25px", border: "3px solid red", borderRadius: "10px",boxShadow: "#8f6B29 0px 0px 20px 0px" }}>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="text" className="text-center" placeholder="Amount to Withdraw" onInput={ (e) =>  { sellerWithdraw = e.target.value } } />
                                                </Form.Group>
                                                <Button variant="warning" id="btnBuyPBX" className="goldenBack w-100" style={{ height: "50px"}} onClick={() => btnBuy() }>
                                                    <b>Withdraw</b>
                                                </Button>
                                            </div>
                                        </Col>
                                        </Row>
                                    
                                </>
                            )
                        }
                    })
                } */}

              </Container>
                
            </div>
        </div>
        <br/><br/>
      </>
    );
  }

export default PresaleV2;