import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";

export default class FooterAPR extends React.Component {
    render() {
        return (
              <Container style={{ fontSize: "18px", maxWidth: "838px" }}>
                <Row>
                  <Col lg="12">
                    <br />
                    <span style={{ letterSpacing: ".5em" }}>The Pre-Sale Bonus</span>
                    <br />
                    <br />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '5px'}}>
                  {/* <Col lg="4">
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px", fontSize: "16px" }}> 15 Oct 2021 :</span>
                              <span style={{ fontSize: "16px" }}>Bonus 30%</span>
                            </div>
                          </Card.Body>
                      </Card>
                  </Col>
                  <Col lg="6">
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px", fontSize: "16px" }}> 1 Nov 2021 :</span>
                              <span style={{ fontSize: "16px" }}>Bonus 20%</span>
                            </div>
                          </Card.Body>
                      </Card>
                  </Col> */}
                  <Col lg="12">
                      <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff" }}>
                          <Card.Body>
                            <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                              <span style={{ marginLeft: "10px", fontSize: "16px" }}> 1 - 31 Dec 2021 :</span>
                              <span style={{ fontSize: "16px" }}>Bonus 10%</span>
                            </div>
                          </Card.Body>
                      </Card>
                  </Col>
                </Row>
              </Container>
        )
    }
}