import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import Presale from './Pages/Presale';
import PresaleV2 from './Pages/PresaleV2';


function App() {
  const Error404 = () => <h1 className="text-center"><br/><br/><img src="https://cdn4.iconfinder.com/data/icons/gradient-5/50/453-512.png" width="35%" alt="404" /><br/><br/></h1>

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home}>
          <Home />
        </Route>
        <Route exact path="/presale" component={Presale}>
          <Presale />
        </Route>
        <Route exact path="/v2" component={PresaleV2}>
          <PresaleV2 />
        </Route>
        <Route exact path="*">{Error404}</Route>
      </Switch>
    </>
    
  );
}

export default App;
