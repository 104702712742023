import React, {useEffect, useState} from "react";
import {
  web3,
  web3getAccount,
  CheckBalance,
  Owner,
  totalUsers,
  getAllUserInfos
} from "../web3/web3Provider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Image, Badge, Modal, Table } from "react-bootstrap";


function Header() {
  const [PBXBalance, setPBXBalance] = useState({})
  const [show, setShow] = useState(false);
  const [ownOn, setOwnerOn] = useState([])
  const [userData, setUserData] = useState([])
  const [getTotalUsers, setGetTotalUsers] = useState([])

  const timeConverter = (UNIX_timestamp) => {
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' - ' + hour + ':' + min + ':' + sec ;
    return time;
  }

    useEffect(() => {
      const preMyFunction = async() => {
        await getPBXbalance(); 
        await showGetAllUser();
        await chkOwner().then(async(res) => {
          if(res === true)
          {
            await setDataAllUsers()
          }
        })
      }
      preMyFunction()
      
     
    }, [ownOn[0]])

    const chkOwner = async () => {
      await web3getAccount().then( async (res) => {
        await Owner().then((result) => {
          let isAcc = res.toLowerCase();
          let isOwner = result.toLowerCase();

          isAcc === isOwner
            ? setOwnerOn([true])
            : setOwnerOn([false])

          if(ownOn[0] !== undefined)
          {
            ownOn[0] === true
              ? console.log("Is Owner logon | isOwner = ", ownOn[0])
              : console.log("Is User logon | isOwner = ", ownOn[0])
          }

        })
      })

      return ownOn[0];
      //console.log(tempRes)
    }
    
    const setDataAllUsers = () => {
      web3getAccount().then((res) => {
        getAllUserInfos(res).then((result) => {
          setUserData([...userData, result])
          // tempRes.push({result})
          //console.log(userData)
          //console.log(getTotalUsers[0])
          
        })
      })
    }

    const showDetaAllUsers = () => {
      
      if(ownOn[0] !== undefined && ownOn[0] === true)
      {
        if(userData[0] !== undefined){
          /* Debug */
          //console.log(userData[0])
          // return (
          //   JSON.stringify(userData[0])
          // )

          /* Production */
          return(
            userData[0].map((item, index) =>
              (
                <tr key={index} style={{ fontSize: "12px", textAlign: "center"}}>
                  <td>{index+1}</td>
                  <td>{timeConverter(item.lasttime)}</td>
                  <td><a href="#" onClick={ () =>  window.open(`https://bscscan.com/address/${item.address}`, '_blank') }>{item.address}</a></td>
                  <td>{item.fname} {item.lname}</td>
                  <td><a href="#" onClick={ () => window.location.href = `mailto: ${item.email}`}>{item.email}</a></td>
                  <td>{item.country}</td>
                  <td>{item.BUSD}</td>
                  <td>{item.PBX}</td>
                  <td>{item.PBXbonus}</td>
                  <td>{item.PBXclaim}</td>
                </tr>
              )
            )
          )

        }
      }
    }
  
    
    const getPBXbalance = () => {
      web3getAccount().then((res) => {
        CheckBalance(res).then((result) => {
          setPBXBalance({"account":`${res.substr(0, 6)}...${res.substr(-4)}`, "balance": (Number(web3.utils.fromWei(result))).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
        })
      })
    }

   

    const showGetAllUser = async () => {
      await totalUsers().then( async (res0) => {
        setGetTotalUsers([res0]);
      })
    }

    

    return (
      
      <>
        <div className="d-flex justify-content-between" style={{ padding: ".85rem"}}>
          <Button variant="warning" className="goldenBack btn-sm" onClick={ () => window.location.href="./" }><Image src="./iconHome.png" height="12" /> <small>Home</small></Button>
          <div>
            <Badge bg="light" text="dark">
            { PBXBalance.account }
            </Badge>{' '}
              <Badge className="goldenBack"> <Badge bg="light" text="dark">
                 { PBXBalance.balance }
              </Badge>{' '}
            PBX</Badge>
          </div>
        </div>
        <hr />
        {
          ownOn[0] === true
            ? ( 
              <>
            <div className="d-flex justify-content-end" style={{ padding: ".85rem"}}>
              <Button variant="info" className="btn-sm" onClick={() => setShow(true)}><Image src="./img/alluser.png" height="24" /> <small>Show User All</small></Button>
            </div>
            <Modal
              size="lg"
              show={show}
              onHide={() => setShow(false)}
              dialogClassName="modal-100w"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                  All User Pre-Sale <Badge bg="info">{getTotalUsers[0]}</Badge>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover responsive id="tblUserPreSale">
                  <thead>
                    <tr style={{ fontSize: "14px", textAlign: "center" }}>
                      <th>No.</th>
                      <th>Date</th>
                      <th>Address</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Country</th>
                      <th>BUSD</th>
                      <th>PBX</th>
                      <th>PBX Bonus</th>
                      <th>Claimed</th>
                    </tr>
                  </thead>
                  <tbody>
                    { showDetaAllUsers() }
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
            </>
            )
            : ""
        }
        
          
        <br />
        <br />
      </>
    );
  }

export default Header;