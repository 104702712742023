import React from 'react';
/*import Web3 from 'web3';
import { MemberInfo, CreateAccount, SendWCACALL, CheckBalance, web3getAccount, sendToken } from "../web3/web3Provider";*/
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const btnIsWeb3 = () => {
  return (
    <Link to='/v2'>
      <Button 
        variant="warning" 
        className="goldenBack" 
        style={{ fontFamily: "Montserrat", 
        padding: "10px 25px",
        width: "300px"
        }} 
      >
        Payment via <img src="./img/BUSD.png" height="24"></img> <b>BUSD</b>
        </Button>
    </Link>
  );
}
const btnIsNotWeb3 = () => {
  return (
    <Link to={{ pathname: "https://metamask.io/download.html" }} target="_parent">
      <Button 
        variant="dark" 
        style={{ 
          fontFamily: "Montserrat", 
          padding: "10px 25px", 
          width: "300px"
        }} 
      >
        Payment via <Image src="https://metamask.io/images/favicon.png" /> <b>Metamask</b>
        </Button>
    </Link>
  );
}

export default class Home extends React.Component {

  render(){
  return (
    <>
    <br/><br/>
          <div className="text-center">
            <header className="App-header">
              <p><img src="pbx.gif" alt="logo" /></p>
              <br/>
              <h3 style={{ letterSpacing: ".3em", fontFamily: "Montserrat", fontSize: "15px", textTransform: "uppercase" }}>
              Please select your payment
              </h3>
              <br/>
              <p style={{ fontFamily: "Montserrat", fontSize: "15px", color: "rgb(90, 96, 108)"}}>Minimum Pre-sale is 1,000 PBX.</p>
              {
                typeof window.ethereum === 'undefined'
                  ? btnIsNotWeb3()
                  : btnIsWeb3()
              }
              <br/><br/>
              <p>
              <Link to={{ pathname: "https://docs.google.com/forms/d/e/1FAIpQLSf5ZJmklSv5FD1ext0RwP-ZFRqguYtkVtFLDSPVzTj6Dx2HnQ/viewform?fbclid=IwAR2PK5yBRl28b1jty4eGD5GuPAPqNhaWwc5BJV48U43qPuQBQVrdPU6bvrc" }} target="_parent">
                <Button 
                  variant="dark" 
                  className="bg-light text-dark"
                  style={{ fontFamily: "Montserrat", 
                  padding: "10px 25px", 
                  width: "300px"
                }} 
                >
                  Payment via <img src="./img/PAYPAL.png" height="24"></img> <b>PayPal</b>
                  </Button>
              </Link>
              </p>
            </header>
            <br/><br/><br/><br/><br/>
            <footer>
            <p>
              <Link 
                to={{ pathname: "https://github.com/PolarBear100X/PBX/blob/main/Manual%20for%20Buying%20PBX%20token%2028.10.2021.pdf" }} 
                target="_parent" 
                className="text-dark" 
                style={{ textDecoration: 'none' }}
              >
              <img src="./img/metamask.png" height="48"></img>&nbsp;&nbsp;&nbsp;
              <img src="./img/phone.png" height="64"></img>&nbsp;&nbsp;&nbsp;
              <b>Manual</b>
              </Link>
              </p>
            </footer>
          </div>
    </>
      
        
    );
  }
}
